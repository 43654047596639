var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("登記預約中心")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("預約管理")])],1)],1)])]),_c('b-card',[_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.bookingPresets,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('RouterLink',{attrs:{"to":{ name: 'BookingList', params: { id: data.item.id } }}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"cell(actions)",fn:function(data){return [(_vm.checkPermission([_vm.consts.BOOKING_LIST]))?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"inverse-dark"},on:{"click":function($event){return _vm.showFormUrl(data.item)}}},[_c('i',{staticClass:"fa fa-link"})]):_vm._e(),(_vm.checkPermission([_vm.consts.BOOKING_LIST]))?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"inverse-warning","to":{ name: 'BookingList', params: { id: data.item.id } }}},[_c('span',{staticClass:"mdi mdi-format-list-bulleted"}),_vm._v(" 預約紀錄 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.BOOKING_SETTING]))?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"inverse-info","to":{
                    name: 'BookingBranchPresetEdit',
                    params: {
                      id: data.item.id,
                      branch: _vm.branchId
                    }
                  }}},[_c('i',{staticClass:"mdi mdi-timetable"}),_vm._v(" 分店時段設定 ")]):_vm._e()]}}])})],1)],1)]),_c('CustomPagination',{attrs:{"currentPage":_vm.query.page,"totalRows":_vm.totalRows,"perPage":_vm.query.per_page},on:{"page-change":_vm.handlePageChange,"per-page-change":_vm.handlePerPageChange}})],1)],1),_c('FormUrlModal',{ref:"formUrlModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }